import React, { useContext, useReducer } from 'react';
import {
  SurveyResponse,
  JSTQuestionID,
  ISessionStartResponse,
  ISurveyResults,
  IJSTQuestion,
  IJSTQuestionResponse,
  Resource,
  ISurveyTriage,
  ISurveyPlan,
  initializeJSTSession,
} from './jst';
import { SearchResult } from './search';
import Immutable from 'immutable';
import useInterval from '../utils/useInterval';

// history of requests to JST API
export type JSTHistory =
  | 'get-keywords'
  | 'start-session'
  | 'upsert-initial-questions'
  | 'get-results'
  | 'upsert-new-questions';

type Error = { message: string; verbose: any };

type Action =
  | { type: 'set-keywords'; payload: string[] }
  | { type: 'start-session'; payload: ISessionStartResponse }
  | { type: 'upsert-initial-questions'; payload: IJSTQuestion[] }
  | {
      type: 'upsert-new-questions';
      payload: IJSTQuestion;
    }
  | { type: 'update-questions'; payload: IJSTQuestion[] }
  | { type: 'set-symptoms'; payload: string[] }
  | { type: 'set-error'; payload: string | undefined | Error }
  | { type: 'set-info'; payload: string | undefined }
  | { type: 'begin-fetch' }
  | { type: 'stop-loading' } // for handling edge cases
  | {
      type: 'upsert-survey-response';
      payload: {
        _id: string;
        vastaus?: SurveyResponse;
        lisatieto?: string;
        kesto?: string;
      };
    }
  | { type: 'set-results'; payload: ISurveyResults }
  | { type: 'set-triage'; payload: ISurveyTriage[] }
  | { type: 'set-plan'; payload: ISurveyPlan[] }
  | { type: 'reset-description' }
  | { type: 'set-resources'; payload: Resource[] }
  | { type: 'set-gender'; payload: 'M' | 'F' | '' }
  | { type: 'set-age'; payload: number }
  | { type: 'overwrite-data'; payload: Record<string, any> }
  | { type: 'finish-survey' }
  | { type: 'updating-results' }
  | { type: 'view-survey' }
  | { type: 'update-search-results'; payload: SearchResult[] }
  | { type: 'fetching-search-results' }
  | {
      type: 'restore-from-local-storage';
      payload: { data: Record<string, any>; keyPath: string[] };
    }
  | { type: 'set-is-newest-results'; payload: boolean };

export type Dispatch = (action: Action) => void;

export type LastSymptomsSearch = {
  symptoms?: Immutable.List<string>;
  age?: number;
  gender?: 'M' | 'F' | '';
};

export interface IState {
  symptoms: Immutable.List<string>;
  lastSymptomsSearch: Immutable.Record<LastSymptomsSearch>;
  session?: Immutable.Record<ISessionStartResponse>;
  error?: string;
  info?: string;
  loading:
    | false
    | 'fetching-data'
    | 'fetching-survey-update'
    | 'updating-results';
  history: Immutable.List<JSTHistory>;
  keywords: Immutable.List<string>;
  description:
    | 'uninitialized'
    | 'disallow-survey-update'
    | 'allow-survey-update'
    | 'auto-survey-update'
    | 'finished-survey';
  responses: Immutable.Map<
    JSTQuestionID,
    Immutable.Record<IJSTQuestionResponse>
  >;
  previousResultsResponses: Immutable.Map<
    JSTQuestionID,
    Immutable.Record<IJSTQuestionResponse>
  >;
  questions: Immutable.List<Immutable.Record<IJSTQuestion>>;
  results?: Immutable.Record<ISurveyResults>;
  triage?: Immutable.Record<ISurveyTriage>;
  plan?: Immutable.Record<ISurveyPlan>;
  resources?: Immutable.List<Immutable.Record<Resource>>;
  age?: number;
  gender?: 'M' | 'F' | '';
  searchLoading: boolean;
  searchResults?: SearchResult[];
  searchIsNewestResults: boolean;
}

// React expects an object literal and will merge updates with the previous state -> immutable part needs to be nested inside JS object
// https://github.com/immutable-js/immutable-js/wiki/Immutable-as-React-state
export type ImmutableDataState = Immutable.RecordOf<IState>;
export type State = { data: ImmutableDataState };

type JSTProviderProps = {
  children: React.ReactNode;
  useLocalStorageProviderState: ({
    dispatch,
    callback,
    onEmpty,
  }: {
    dispatch: (action: any) => void;
    callback?: (data: any) => void;
    onEmpty?: () => void;
  }) => Record<string, any>;
  defaultSymptoms: string[];
};

const initialState: State = {
  data: Immutable.Record<IState>({
    symptoms: Immutable.List(),
    lastSymptomsSearch: Immutable.Record<LastSymptomsSearch>({
      age: undefined,
      gender: undefined,
      symptoms: undefined,
    })(),
    session: undefined,
    error: undefined,
    info: undefined,
    loading: false,
    history: Immutable.List(),
    keywords: Immutable.List(),
    description: 'uninitialized',
    responses: Immutable.Map(),
    previousResultsResponses: Immutable.Map(),
    questions: Immutable.List(),
    results: undefined,
    triage: undefined,
    plan: undefined,
    resources: undefined,
    age: undefined,
    gender: undefined,
    searchLoading: false,
    searchResults: undefined,
    searchIsNewestResults: false,
  })(),
};

const JSTStateContext = React.createContext<ImmutableDataState | undefined>(
  undefined
);
const JSTDispatchContext = React.createContext<Dispatch | undefined>(undefined);

function defaultResponse(
  symptoms: Immutable.List<string>,
  question: Immutable.Record<IJSTQuestion>
): Immutable.Record<IJSTQuestionResponse> {
  return Immutable.fromJS(
    symptoms.includes(question.get('hakusanat'))
      ? {
          vastaus: 1,
          kesto: undefined,
          lisatieto: null,
        }
      : undefined
  );
}

function reducer({ data }: State, action: Action): State {
  if (action.type === 'set-keywords') {
    return {
      data: data
        .update('history', (history) => history.push('get-keywords'))
        .set('keywords', Immutable.fromJS(action.payload)),
    };
  }

  if (action.type === 'begin-fetch') {
    return { data: data.set('loading', 'fetching-data') };
  }

  if (action.type === 'start-session') {
    return {
      data: data
        .set(
          'lastSymptomsSearch',
          Immutable.Record<LastSymptomsSearch>({
            symptoms: data.get('symptoms'),
            age: data.get('age'),
            gender: data.get('gender'),
          })()
        )
        .set('questions', Immutable.List())
        .set('responses', Immutable.Map())
        .update('history', (history) => history.push(action.type))
        .set('session', Immutable.fromJS(action.payload)),
    };
  }

  if (action.type === 'upsert-initial-questions') {
    const payload: Immutable.List<Immutable.Record<
      IJSTQuestion
    >> = Immutable.fromJS(action.payload);

    const responses: Immutable.Map<
      JSTQuestionID,
      Immutable.Record<IJSTQuestionResponse>
    > = payload.reduce((acc, val) => {
      const res = defaultResponse(data.get('symptoms'), val);
      return res ? acc.set(val.get('_id'), res) : acc;
    }, Immutable.Map({}));

    return {
      data: data
        .set('loading', false)
        .set(
          'description',
          responses.count() === 0
            ? 'disallow-survey-update'
            : 'allow-survey-update'
        )
        .set('responses', responses)
        .update('history', (history) => history.push(action.type))
        .update('questions', (questions) => questions.concat(payload)),
    };
  }

  if (action.type === 'upsert-new-questions') {
    const payload: Immutable.Record<IJSTQuestion> = Immutable.fromJS(
      action.payload
    );

    const response = defaultResponse(data.get('symptoms'), payload);

    return {
      data: data
        .set('loading', false)
        .set('description', 'disallow-survey-update')
        .update('history', (history) => history.push(action.type))
        .update('questions', (questions) =>
          questions.push(Immutable.fromJS(action.payload))
        )
        .update('responses', (responses) =>
          response ? responses.set(payload.get('_id'), response) : responses
        ),
    };
  }

  if (action.type === 'update-questions') {
    return { data: data.set('questions', Immutable.fromJS(action.payload)) };
  }

  if (action.type === 'upsert-survey-response') {
    // Use `updates` for merging with the current `vastaus` field in the question specified by _id
    const { _id, ...updates } = action.payload;
    const updatedWithResponse = data.updateIn(
      ['responses', _id],
      (previous) => {
        return !previous
          ? Immutable.fromJS(updates)
          : previous.merge(Immutable.fromJS(updates));
      }
    );
    // Has user answered all questions -> allow clicking on continue button(s)?
    const answeredAll =
      updatedWithResponse.get('responses').count() ===
      updatedWithResponse.get('questions').count();
    const answeredOne = updatedWithResponse.get('responses').count() > 0;

    return {
      data: updatedWithResponse.set(
        'description',
        answeredAll
          ? 'auto-survey-update'
          : answeredOne
          ? 'allow-survey-update'
          : 'disallow-survey-update'
      ),
    };
  }

  if (action.type === 'set-symptoms') {
    return { data: data.set('symptoms', Immutable.fromJS(action.payload)) };
  }

  if (action.type === 'set-error') {
    const msg =
      typeof action.payload === 'object'
        ? action.payload.message
        : action.payload;
    return { data: data.set('error', Immutable.fromJS(msg)) };
  }

  if (action.type === 'set-info') {
    return { data: data.set('info', Immutable.fromJS(action.payload)) };
  }

  if (action.type === 'stop-loading') {
    return { data: data.set('loading', false) };
  }

  if (action.type === 'set-results') {
    return {
      data: data
        .set('results', Immutable.fromJS(action.payload))
        .set('previousResultsResponses', data.get('responses')),
    };
  }

  if (action.type === 'set-triage') {
    return {
      data: data.set('triage', Immutable.fromJS(action.payload)),
    };
  }

  if (action.type === 'set-plan') {
    return {
      data: data.set('plan', Immutable.fromJS(action.payload)),
    };
  }

  if (action.type === 'reset-description') {
    return {
      data: data.set('description', 'disallow-survey-update'),
    };
  }

  if (action.type === 'set-resources') {
    return {
      data: data.set('resources', Immutable.fromJS(action.payload)),
    };
  }

  if (action.type === 'set-age') {
    return {
      data: data.set('age', action.payload),
    };
  }

  if (action.type === 'set-gender') {
    return {
      data: data.set('gender', action.payload),
    };
  }

  if (action.type === 'overwrite-data') {
    return {
      data: Immutable.fromJS(action.payload),
    };
  }

  if (action.type === 'finish-survey') {
    return {
      data: data.set('description', 'finished-survey'),
    };
  }

  if (action.type === 'view-survey') {
    return {
      data: data.set('description', 'auto-survey-update'),
    };
  }

  if (action.type === 'updating-results') {
    return {
      data: data.set('loading', 'updating-results'),
    };
  }
  if (action.type === 'fetching-search-results') {
    return {
      data: data.set('searchLoading', true),
    };
  }
  if (action.type === 'update-search-results') {
    return {
      data: data
        .set('searchLoading', false)
        .set('searchResults', action.payload),
    };
  }
  if (action.type === 'set-is-newest-results') {
    return {
      data: data.set('searchIsNewestResults', action.payload),
    };
  }

  if (action.type === 'restore-from-local-storage') {
    const data = action.payload.data.data;
    return {
      data: initialState.data.merge(
        Object.keys(data).reduce((acc, key: any) => {
          return {
            ...acc,
            [key]:
              (data[key] && Immutable.fromJS(data[key])) ||
              initialState.data.get(key),
          };
        }, {})
      ),
    };
  }

  return { data };
}

export function useJSTState(): Immutable.RecordOf<IState> {
  const context = useContext(JSTStateContext);
  if (context === undefined) {
    throw new Error('useJSTState must be used inside JSTContext');
  }
  return context;
}

export function useJSTDispatch(): Dispatch {
  const context = useContext(JSTDispatchContext);
  if (context === undefined) {
    throw new Error('useJSTDispatch must be used inside JSTContext');
  }
  return context;
}

export function JSTProvider({
  children,
  useLocalStorageProviderState,
  defaultSymptoms,
}: JSTProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { saveState } = useLocalStorageProviderState({
    dispatch,
    callback: (data: any) => {
      if (!data.data.questions || data.data.questions.length === 0) {
        initializeJSTSession({
          symptoms: Immutable.List(defaultSymptoms),
          gender: undefined,
          age: undefined,
          dispatch,
        });
      }
    },
    onEmpty: () => {
      initializeJSTSession({
        symptoms: Immutable.List(defaultSymptoms),
        gender: undefined,
        age: undefined,
        dispatch,
      });
    },
  });

  useInterval(() => saveState(state), 1000);

  return (
    <JSTStateContext.Provider value={state.data}>
      <JSTDispatchContext.Provider value={dispatch}>
        {children}
      </JSTDispatchContext.Provider>
    </JSTStateContext.Provider>
  );
}
