/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useBotState } from '../../api/BotProvider';
import BotMessage from './BotMessage';
import AnswerOptionsContainer from './AnswerOptionsContainer';
import { useRef, useEffect } from 'react';

const styles = {
  root: css`
    padding: 0 2.5rem;
    display: flex;
    flex-direction: column;
  `,
  message: css`
    max-width: 860px;
    width: 100%;
  `,
  breadcrumbs: css`
    margin-bottom: 1.5rem;
  `,
};

export default function BotPage(): JSX.Element {
  const { data } = useBotState();
  const messages = data.get('messages');
  const refMessagesDiv = useRef<any>(null);

  // Only show one more message than for which there are answers
  // (unless each one has already been answered => show all)
  const visibleMessages = data
    .get('messages')
    .take(data.get('answers').count() + 1);

  // Check if should scroll into view
  useEffect(() => {
    const div = refMessagesDiv.current;
    if (div) {
      div.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
        inline: 'start',
      });
    }
  }, [messages]);

  return (
    <div css={styles.root}>
      <div className="bot-messages">
        {visibleMessages.map((message, idx) => (
          <div key={idx} className="bot-message" css={styles.message}>
            <BotMessage messages={message.get('questions')} />
            <AnswerOptionsContainer
              messageIndex={idx}
              options={message.get('answerOptions')}
            />
          </div>
        ))}
        <div ref={refMessagesDiv}></div>
        {/* Used to scroll to bottom */}
      </div>
    </div>
  );
}
