import React, { Component, ReactNode } from 'react';

type Props = {
  logger?: (error: any, errorInfo: any) => void;
  errorHeader: string;
  errorBody: string;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(
    error: any
  ): {
    hasError: boolean;
  } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any): void {
    // You can also log the error to an error reporting service
    if (this.props.logger) this.props.logger(error, errorInfo);
    window.localStorage.removeItem('triaged-state');
  }

  render(): JSX.Element | ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <h1>{this.props.errorHeader}</h1>
          <p>{this.props.errorBody}</p>
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
