import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AuthContext from '../api/Authorization/AuthContext';
import paths from '../paths';

export default function AnonymousOnlyRoute(props: any): JSX.Element {
  const authContext = useContext(AuthContext);

  if (!authContext.isAuthenticated) {
    return <Route {...props} />;
  } else {
    return <Redirect to={paths.root} />;
  }
}
