/** @jsx jsx */
import { css, jsx } from '@emotion/core';
// import React from "react";

const styles = {
  outer: css`
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
};

// This includes header and everything else under children
export function Outer(props: any): JSX.Element {
  return <div css={styles.outer}>{props.children}</div>;
}

// This includes page content sans header under children
export function Inner(props: any): JSX.Element {
  return <div>{props.children}</div>;
}
