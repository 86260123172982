import React from 'react';

const AuthContext = React.createContext<AuthContextValue>({
  isAuthenticated: false,
  checkAuthentication: () => {
    // do nothing
  },
  setAuthentication: (isAuthenticated: boolean) => {
    // do nothing
  },
});

export default AuthContext;

export type AuthContextValue = {
  isAuthenticated: boolean;
  checkAuthentication: () => void;
  // This isn't used right now.
  setAuthentication: (isAuthenticated: boolean) => void;
};
