/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Immutable from 'immutable';
import { AnswerOption, QuestionData } from '../../api/getQuestion';
import { Button } from 'antd';
import { getNextMessage, Dispatch } from '../../api/BotProvider';

const styles = {
  button: css`
    margin: 0.5rem;
    height: max-content !important;
  `,
  buttonText: css`
    display: inline-flex !important;
    align-items: center;
    width: max-content;
    img.emoji {
      height: 16px;
      margin: 0.25rem;
    }
    &:last-child {
      margin-right: 0;
    }
  `,
};

type Option = Immutable.Record<AnswerOption>;
type Message = Immutable.Record<QuestionData>;

type AnswerButtonProps = {
  option: Option;
  dispatch: Dispatch;
  currentAnswer: undefined | Option;
  index: number;
  message: Message;
  onClick?: (event: any) => void;
};

// If true, option is shown as "selected"
export function isOptionSelected(
  option: Option,
  currentAnswer: Option | undefined
): boolean {
  //   return currentAnswer ? option.get("id") === currentAnswer.get("id") : false;
  return option.equals(currentAnswer);
}

// if true, show option as a clickable answer option button
export function isOptionVisible(
  option: Option,
  currentAnswer: Option | undefined
): boolean {
  // for text inputs
  if (option.getIn(['props', 'type'])) {
    return currentAnswer ? option.get('id') === currentAnswer?.get('id') : true;
  }

  // buttons
  return currentAnswer ? isOptionSelected(option, currentAnswer) : true;
}

const defaultOnClick = (props: AnswerButtonProps) => (event: any) => {
  const { option, dispatch, currentAnswer, index, message } = props;

  if (isOptionSelected(option, currentAnswer)) {
    dispatch({ type: 'reset-answer', payload: index });
  } else {
    dispatch({
      type: 'upsert-answer',
      payload: { index: index, answer: option },
    });
    getNextMessage(dispatch, message, option);
  }
};

export function AnswerButton(props: AnswerButtonProps): JSX.Element {
  const { option, currentAnswer, onClick } = props;

  return (
    <Button
      shape="round"
      size="large"
      css={styles.button}
      type={isOptionSelected(option, currentAnswer) ? 'primary' : 'default'}
      onClick={onClick ? onClick : defaultOnClick(props)}
    >
      <span
        dangerouslySetInnerHTML={{
          // check if input field answer ("ansValue"). use "text" prop otherwise
          __html: option.get('ansValue') || option.get('text'),
        }}
        css={styles.buttonText}
      />
    </Button>
  );
}
