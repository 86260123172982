const PUBLIC_URL = process.env.PUBLIC_URL;
const LINK_ICONS = `${PUBLIC_URL}/assets/type-icons`;

export const kuuraLogo = `${PUBLIC_URL}/assets/kuura.png`;

export const icons = {
  chatbot: `${LINK_ICONS}/icon-type-chatbot.png`,
  oirekysely: `${LINK_ICONS}/icon-type-symptom.png`,
  terveysportti: `${LINK_ICONS}/icon-type-terveysportti.png`,
  dokumentti: `${LINK_ICONS}/icon-type-document.png`,
  yhteisotuki: `${LINK_ICONS}/icon-type-support.png`,
};
