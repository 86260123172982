import React, { useContext, useReducer, useEffect, useState } from 'react';
import {
  getSearchParams,
  SearchQueryParams,
  SearchResult,
  useSearchFull,
} from './search';
import { useLocation } from 'react-router-dom';

type Error = { message: string; verbose: any };

type Action =
  | { type: 'update-search-input'; payload: string }
  | {
      type: 'update-search-query';
      payload: SearchQueryParams;
    }
  | {
      type: 'update-search-results';
      payload: Record<string, SearchResult[]> | undefined;
    }
  | { type: 'set-error'; payload: Error }
  | { type: 'fetching-search-results' }
  | {
      type: 'restore-from-local-storage';
      payload: { data: State; keyPath: string[] };
    }
  | { type: 'set-is-newest-results'; payload: boolean };
export type Dispatch = (action: Action) => void;
export type State = {
  input: string;
  queryParams: SearchQueryParams;
  results?: Record<string, SearchResult[]>;
  error?: Error;
  loading: boolean;
  isNewestResults: boolean;
};
type ProviderProps = { children: React.ReactNode };

const SearchStateContext = React.createContext<State | undefined>(undefined);
const SearchDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function reducer(state: State, action: Action): State {
  if (action.type === 'update-search-input') {
    return { ...state, input: action.payload };
  }

  if (action.type === 'update-search-query') {
    const { query, page, type } = action.payload;
    return {
      ...state,
      input: query || '',
      queryParams: {
        query,
        type: type !== undefined ? type : state.queryParams.type,
        page: page || 1,
      },
    };
  }

  if (action.type === 'update-search-results') {
    return {
      ...state,
      results: action.payload,
      loading: false,
      isNewestResults: false,
    };
  }

  if (action.type === 'set-error') {
    return { ...state, error: action.payload };
  }

  if (action.type === 'fetching-search-results') {
    return { ...state, loading: true };
  }

  if (action.type === 'restore-from-local-storage') {
    if (action.payload.keyPath[0] !== 'search') return state;

    // Restore either full state or some subset of state (e.g., results)
    switch (action.payload.keyPath.length) {
      case 1:
        return action.payload.data;
      case 2:
        return {
          ...state,
          [action.payload.keyPath[1]]: action.payload.data,
        };
    }

    return state;
  }
  if (action.type === 'set-is-newest-results') {
    return { ...state, isNewestResults: action.payload };
  }

  return state;
}

export function useSearchState(): State {
  const context = useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error('useSearchState must be used inside SearchContext');
  }
  return context;
}

export function useSearchDispatch(): Dispatch {
  const context = useContext(SearchDispatchContext);
  if (context === undefined) {
    throw new Error('useSearchDispatch must be used inside SearchContext');
  }
  return context;
}

export function SearchProvider({ children }: ProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(reducer, {
    input: '',
    queryParams: { query: '' },
    loading: false,
    isNewestResults: false,
  });
  const { search } = useLocation();
  const queryParams = getSearchParams(search);
  const [initialized, setInitialized] = useState(false);
  const searchList = useSearchFull(dispatch, true);

  // Populate input field if query in URL
  useEffect(() => {
    if (!initialized) {
      dispatch({ type: 'update-search-query', payload: queryParams });
      setInitialized(true);
    }
    if (state.results === undefined && state.loading === false) {
      dispatch({ type: 'fetching-search-results' });
      searchList.search({
        order: 'modified, newest first',
        groupResults: true,
      });
    }
  }, [initialized, queryParams, searchList, state.results, state.loading]);

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  );
}
