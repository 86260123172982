import React, { ReactNode, useContext, useReducer } from 'react';
import { BotProvider } from '../api/BotProvider';
import BotPage from '../pages/BotPage';
import { DocumentProvider } from '../api/DocumentProvider';
import DocumentPage from '../pages/DocumentPage';
import SearchResultsPage from '../pages/SearchResultsPage';
import SymptomsTab from '../pages/SymptomsTab';
import { useLocalStorageState } from '../utils/saveHooks';

export type TabData = {
  title: string;
  content: ReactNode;
};

export type TabPane = {
  title: string;
  type: 'search-results' | 'chatbot' | 'symptom' | 'document';
  closable?: boolean;
  key: any;
  icon?: string;
  state: any;
  id?: string;
};

export type TabPayload = {
  attr: string;
  type: any;
  key: any;
  icon?: string;
  id?: string;
};

type Action =
  | {
      type: 'open-tab';
      payload: TabPane;
    }
  | { type: 'close-tab'; payload: any }
  | { type: 'change-tab'; payload: any }
  | { type: 'update-tab-state'; payload: { key: any; state: any } }
  | {
      type: 'restore-from-local-storage';
      payload: { data: State; keyPath: string[] };
    };

export type Dispatch = (action: Action) => void;

export type State = { panes: TabPane[]; activeKey: string };

type ProviderProps = { children: React.ReactNode };

const TabStateContext = React.createContext<State | undefined>(undefined);
const TabDispatchContext = React.createContext<Dispatch | undefined>(undefined);

const initialState: State = {
  activeKey: '0',
  panes: [
    {
      title: 'Hakutulokset',
      type: 'search-results',
      closable: false,
      key: '0',
      state: null, // for localStorage persistence
    },
  ],
};

function onRemoveTab(state: State, targetKey: any): State {
  const removableIndex = state?.panes?.findIndex(
    (elt) => elt.key === targetKey
  );
  const removableKey = state?.panes[removableIndex].key;
  const newPanes = state?.panes?.filter((elt) => elt.key !== targetKey);

  return {
    panes: newPanes || [],
    activeKey:
      removableKey === state.activeKey
        ? state?.panes[removableIndex - 1].key
        : state.activeKey,
  };
}

export function createPane(
  state: State,
  dispatch: Dispatch,
  pane: TabPane
): JSX.Element | undefined {
  if (pane.type === 'search-results') {
    return <SearchResultsPage />;
  }

  if (pane.type === 'chatbot') {
    return (
      <BotProvider
        symptom={pane.state?.symptom || ''}
        useLocalStorageProviderState={({ callback, dispatch, onEmpty }) =>
          useLocalStorageState({
            keyPath: ['tab-provider-states', pane.key],
            dispatch,
            callback,
            onEmpty,
          })
        }
      >
        <BotPage />
      </BotProvider>
    );
  }

  if (pane.type === 'symptom') {
    return (
      <SymptomsTab
        tabKey={pane.state.tabKey}
        defaultSymptoms={pane.state.defaultSymptoms}
        useLocalStorageProviderState={({ callback, dispatch, onEmpty }) =>
          useLocalStorageState({
            keyPath: ['tab-provider-states', pane.key],
            dispatch,
            callback,
            onEmpty,
          })
        }
      />
    );
  }
  if (pane.type === 'document') {
    return (
      <DocumentProvider
        docId={pane.state?.docId || ''}
        useLocalStorageProviderState={({ callback, dispatch, onEmpty }) =>
          useLocalStorageState({
            keyPath: ['tab-provider-states', pane.key],
            dispatch,
            callback,
            onEmpty,
          })
        }
      >
        <DocumentPage />
      </DocumentProvider>
    );
  }
}

export function makeTabPayload({
  attr,
  type,
  icon,
  key,
  id,
}: TabPayload): TabPane | undefined {
  const base = { title: attr, closable: true, icon, key, type };

  if (type === 'symptom') {
    return {
      ...base,
      state: {
        which: 'symptoms-page',
        tabKey: key,
        defaultSymptoms: attr.split(',').map((el) => el.trim().toLowerCase()),
      },
    };
  }
  if (type === 'chatbot') {
    return {
      ...base,
      state: {
        symptom: attr,
      },
    };
  }
  if (type === 'document') {
    return {
      ...base,
      state: {
        docId: id,
      },
    };
  }
}

function tabReducer(state: State, action: Action): State {
  if (action.type === 'open-tab') {
    return {
      ...state,
      activeKey: action.payload.key,
      panes: [...(state?.panes || []), action.payload],
    };
  }

  if (action.type === 'close-tab') {
    return onRemoveTab(state, action.payload);
  }

  if (action.type === 'change-tab') {
    return { panes: [...(state?.panes || [])], activeKey: action.payload };
  }

  if (action.type === 'update-tab-state') {
    return {
      ...state,
      panes: state.panes.map((pane) =>
        pane.key === action.payload.key
          ? { ...pane, state: action.payload.state }
          : pane
      ),
    };
  }

  if (action.type === 'restore-from-local-storage') {
    return action.payload.data;
  }

  return state;
}

export function useTabState(): State {
  const context = useContext(TabStateContext);
  if (context === undefined) {
    throw new Error('useTabState must be used inside TabContext');
  }
  return context;
}

export function useTabDispatch(): Dispatch {
  const context = useContext(TabDispatchContext);
  if (context === undefined) {
    throw new Error('useTabDispatch must be used inside TabContext');
  }
  return context;
}

export function TabProvider({ children }: ProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(tabReducer, initialState);

  return (
    <TabStateContext.Provider value={state}>
      <TabDispatchContext.Provider value={dispatch}>
        {children}
      </TabDispatchContext.Provider>
    </TabStateContext.Provider>
  );
}
