/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useEffect } from 'react';
import {
  useSearchFull,
  SearchResult,
  getLinkTypeObject,
} from '../../api/search';
import { Typography, Empty, Button, PageHeader } from 'antd';
import { NavLink } from 'react-router-dom';
import theme from '../../theme';
import { useSearchDispatch, useSearchState } from '../../api/SearchProvider';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import {
  makeTabPayload,
  useTabDispatch,
  useTabState,
} from '../../components/TabProvider';
import { useLocalStorageState } from '../../utils/saveHooks';
import React from 'react';
import { useState } from 'react';
import { take } from 'ramda';

const { Text } = Typography;

const styles = {
  root: css`
    padding: 0 2.5rem;
  `,
  summary: css`
    display: block;
    margin-bottom: 1.5rem;
  `,
  results: css`
    .ant-skeleton {
      max-width: 500px;
    }
  `,
  resultItem: css`
    margin: 1rem 0;
    display: flex;
    align-items: flex-start;
  `,
  resultItemLeft: css`
    margin-right: 1rem;
    margin-top: 6px;
    img {
      width: 25px;
      height: auto;
    }
  `,
  link: css`
    color: ${theme.kuurablue};
    font-size: 1rem;
    padding: 0;
  `,
  resultDescription: css`
    max-width: 500px;
    word-break: break-word;
  `,
  mainLink: css`
    display: block;
  `,
  proposals: css`
    display: flex;
    flex-direction: column;
    a {
      font-size: 14px;
    }
  `,
  empty: css`
    margin: 2rem;
    width: max-content;
  `,
};

export const uniformTitle = (titleList: string): string =>
  titleList
    .split(',')
    .map((el) => el.trim().toLowerCase())
    .join(',');

export function ResultItem({
  title,
  type,
  description,
  proposals,
  url,
  id,
}: SearchResult): JSX.Element {
  const { queryParams } = useSearchState();
  const linkData = getLinkTypeObject(type);
  const dispatch = useTabDispatch();
  const tabState = useTabState();

  const createLink = (title: string) => {
    const linkUrl = makeTitle(title);
    const tabPayload = makeTabPayload({
      attr: title,
      type,
      icon: linkData?.icon || '',
      key: `${parseInt(tabState.panes[tabState.panes.length - 1].key) + 1}`,
      id: id,
    });

    // Handle external links
    if (linkUrl.startsWith('http://') || linkUrl.startsWith('https://'))
      return (
        <a
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          css={styles.link}
        >
          {title}
        </a>
      );

    if (!linkData || !tabPayload) return <span>{title}</span>;

    return (
      <Button
        type="link"
        css={styles.link}
        onClick={() =>
          dispatch({
            type: 'open-tab',
            payload: tabPayload,
          })
        }
      >
        {title}
      </Button>
    );
  };

  const makeTitle = (title: string) =>
    linkData?.toLink
      ? linkData.toLink({
          query: queryParams.query,
          attr: uniformTitle(title),
          url: url,
          id: id,
        })
      : '#';

  return (
    <div css={styles.resultItem}>
      <div css={styles.resultItemLeft}>
        {linkData?.icon && <img src={linkData.icon} alt={linkData.title} />}
      </div>
      <div>
        <div css={styles.mainLink}>{createLink(title)}</div>
        {proposals && (
          <div css={styles.proposals}>
            {proposals?.map((proposal) => (
              <NavLink
                key={proposal}
                to={makeTitle(proposal)}
                css={styles.link}
              >
                {proposal}
              </NavLink>
            ))}
          </div>
        )}
        <Typography css={styles.resultDescription}>{description}</Typography>
      </div>
    </div>
  );
}

export function SearchResultGroup({
  groupTitle,
  results,
  showMoreGroupsState,
}: {
  groupTitle: string;
  results: SearchResult[];
  showMoreGroupsState: [
    Record<string, any>,
    React.Dispatch<Record<string, any>>
  ];
}): JSX.Element {
  const [showMoreGroups, setShowMoreGroups] = showMoreGroupsState;
  const groups: Record<string, string> = {
    document: 'Dokumentit',
    terveysportti: 'Terveysportti',
    symptom: 'Oirekysely',
    chatbot: 'Chatbot',
    support: 'Yhteisötuki',
  };
  const lengthToShow = results.length <= 3 ? results.length : 3;
  const limitVisibility = !showMoreGroups[groupTitle];

  return results && results.length > 0 ? (
    <div>
      <PageHeader
        title={groups[groupTitle]}
        subTitle={
          !limitVisibility ? (
            <Button
              onClick={() =>
                setShowMoreGroups({ ...showMoreGroups, [groupTitle]: false })
              }
            >
              Näytä vähemmän
            </Button>
          ) : (
            limitVisibility &&
            lengthToShow < results.length && (
              <Button
                onClick={() =>
                  setShowMoreGroups({ ...showMoreGroups, [groupTitle]: true })
                }
              >
                Näytä lisää
              </Button>
            )
          )
        }
      />
      {take(limitVisibility ? lengthToShow : results.length, results).map(
        (result, jdx) => (
          <ResultItem key={jdx} {...{ ...result, type: groupTitle }} />
        )
      )}
    </div>
  ) : (
    <React.Fragment />
  );
}

export default function SearchResultsPage(): JSX.Element {
  const searchState = useSearchState();
  const searchDispatch = useSearchDispatch();
  const { search } = useSearchFull(searchDispatch);
  const showMoreGroupsState = useState<Record<string, any>>({});

  const { saveState } = useLocalStorageState({
    keyPath: ['search'],
    dispatch: searchDispatch,
  });

  // TODO: remove this -- move into search bar?
  useEffect(() => {
    if (searchState.queryParams.query) {
      search(searchState.queryParams);
      saveState(searchState);
    }
  }, [saveState, search, searchState, searchState.queryParams]);

  return (
    <div css={styles.root}>
      <div css={styles.results}>
        {searchState.loading ? (
          <LoadingSkeleton />
        ) : (
          <div>
            {searchState.queryParams.query && !searchState.isNewestResults && (
              <Text type="secondary" css={styles.summary}>
                Tulokset haulle &quot;{searchState.queryParams.query}&quot;
              </Text>
            )}
            {searchState.isNewestResults && (
              <Text type="secondary" css={styles.summary}>
                Viimeisimmät muutokset sisällössä
              </Text>
            )}
            {searchState.results &&
            Object.values(searchState.results).length > 0 ? (
              Object.keys(searchState.results)
                .sort() // Order will be chatbot, documents, symptoms, terveysportti
                .map((group, idx) => (
                  <SearchResultGroup
                    key={idx}
                    groupTitle={group}
                    showMoreGroupsState={showMoreGroupsState}
                    results={
                      (searchState.results && searchState.results[group]) || []
                    }
                  />
                ))
            ) : (
              <Empty
                css={styles.empty}
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Ei hakutuloksia"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
}
