import React, { useContext, useEffect, useReducer } from 'react';
import {
  DocumentData,
  getDocument,
  getDocumentFile,
  getDocumentPreview,
} from './document';

type Error = { message: string; verbose: any };

type Action =
  | { type: 'update-document'; payload: DocumentData }
  | { type: 'fetching-document' }
  | { type: 'set-error'; payload: Error }
  | { type: 'set-file'; payload: File }
  | { type: 'set-preview'; payload: File };
export type Dispatch = (action: Action) => void;
export type State = {
  loading: boolean;
  documentData: DocumentData | null;
  error?: Error;
  documentFile?: File;
  documentPreview?: File;
};
type DocumentProviderProps = {
  children: React.ReactNode;
  docId: string;
  useLocalStorageProviderState: ({
    dispatch,
    callback,
    onEmpty,
  }: {
    dispatch: (action: any) => void;
    callback?: () => void;
    onEmpty?: () => void;
  }) => Record<string, any>;
};

const DocumentStateContext = React.createContext<State | undefined>(undefined);
const DocumentDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function documentReducer(state: State, action: Action): State {
  if (action.type === 'update-document') {
    return { ...state, documentData: action.payload, loading: false };
  }
  if (action.type === 'fetching-document') {
    return { ...state, loading: true };
  }
  if (action.type === 'set-error') {
    return { ...state, error: action.payload };
  }
  if (action.type === 'set-file') {
    return { ...state, documentFile: action.payload };
  }
  if (action.type === 'set-preview') {
    return { ...state, documentPreview: action.payload };
  }
  return state;
}

export function useDocumentState(): State {
  const context = useContext(DocumentStateContext);
  if (context === undefined) {
    throw new Error('useDocumentState must be used inside DocumentContext');
  }
  return context;
}

export function useDocumentDispatch(): Dispatch {
  const context = useContext(DocumentDispatchContext);
  if (context === undefined) {
    throw new Error('useDocumentDispatch must be used inside DocumentContext');
  }
  return context;
}

export function DocumentProvider({
  children,
  useLocalStorageProviderState,
  docId,
}: DocumentProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(documentReducer, {
    documentData: null,
    loading: true,
  });

  useEffect(() => {
    dispatch({ type: 'fetching-document' });
    getDocument(docId).then((res) => {
      dispatch({ type: 'update-document', payload: res });
      if (res?.previewAvailable) {
        getDocumentPreview(docId).then((res) => {
          dispatch({ type: 'set-preview', payload: res });
        });
      } else {
        getDocumentFile(docId).then((res) => {
          dispatch({ type: 'set-preview', payload: res });
          dispatch({ type: 'set-file', payload: res });
        });
      }
    });
  }, [docId]);

  return (
    <DocumentStateContext.Provider value={state}>
      <DocumentDispatchContext.Provider value={dispatch}>
        {children}
      </DocumentDispatchContext.Provider>
    </DocumentStateContext.Provider>
  );
}
