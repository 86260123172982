/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React from 'react';
import { Row, Col, Typography, Button } from 'antd';
import theme from '../theme';
import { UserData } from '../api/user';
import { useLogout } from '../api/Authorization/Authentication';
import { useUserState } from '../api/UserProvider';
import { useLocation, Link } from 'react-router-dom';
import paths from '../paths';
import { kuuraLogo } from '../common/assets';
import SearchBar from './SearchBar';
import { useSearchDispatch, useSearchState } from '../api/SearchProvider';
import { useTabDispatch } from './TabProvider';
import { SearchResult } from '../api/search';
import { useLocalStorageState } from '../utils/saveHooks';

const useStyles = (page: string) => ({
  root: css`
    width: 100%;
    padding: 1rem 2rem;
    border-bottom: none;
  `,
  left: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  `,
  right: css`
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
  `,
  logout: css`
    color: ${theme.secondary};
  `,
  logo: css`
    display: flex;
    align-items: center;
    margin-right: 0.5rem;
    img {
      width: 2rem;
      height: auto;
    }
  `,
  search: css`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  filtersGroup: css`
    margin-top: 1rem;
    margin-left: 1.8rem;
  `,
  filtersRadio: css`
    border: none !important;
    display: inline-flex;
    padding: 0 10px;
    height: 25px !important;
    &::before {
      display: none !important;
    }
    span:last-of-type {
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 14px;
      img {
        height: 16px;
        width: auto;
        margin-right: 0.25rem;
      }
    }
  `,
});

const prettifiedName = (userState: UserData) => {
  const roles: Record<string, string> = {
    DOCTOR: 'Lääkäri',
    NURSE: 'Hoitaja',
  };
  return `${roles[userState.usertype]} ${userState.firstname}`;
};

function LocalLogout() {
  const { loading, request } = useLogout();
  const { pathname } = useLocation();
  const styles = useStyles(pathname);

  return (
    <Button
      type="link"
      css={styles.logout}
      disabled={loading}
      onClick={() => {
        request();
        localStorage.removeItem('oirekysely');
      }}
    >
      Kirjaudu ulos
    </Button>
  );
}

function Auth0Logout() {
  return (
    <form action="/logout" method="POST">
      <input
        type="hidden"
        name="redirectTo"
        value={window.location.href || '/'}
      />

      {process.env.REACT_APP_AUTH0_LOGIN === 'true' ? (
        <Auth0Logout />
      ) : (
        <LocalLogout />
      )}
    </form>
  );
}

function ToSearchResultsLogo() {
  const { pathname } = useLocation();
  const styles = useStyles(pathname);
  const tabDispatch = useTabDispatch();
  const searchDispatch = useSearchDispatch();
  const searchState = useSearchState();
  const { saveState } = useLocalStorageState({
    keyPath: ['search'],
    dispatch: searchDispatch,
  });
  const handleClick = () => {
    tabDispatch({ type: 'change-tab', payload: '0' });
    searchDispatch({ type: 'update-search-input', payload: '' });
    searchDispatch({ type: 'update-search-query', payload: { query: '' } });
    searchDispatch({
      type: 'update-search-results',
      payload: undefined,
    });
    saveState(searchState);
  };
  return (
    <div css={styles.logo}>
      <Link to={paths.root} onClick={handleClick}>
        <img src={kuuraLogo} alt="Kuura logo" />
      </Link>
    </div>
  );
}

export default function Header(): JSX.Element | null {
  const userState = useUserState();
  const { pathname } = useLocation();
  const styles = useStyles(pathname);

  const showSearch = pathname !== paths.login;
  //const isSearchPage = [paths.results, paths.search].includes(pathname);

  return (
    userState && (
      <div css={styles.root}>
        <Row>
          {/* Search */}
          <Col span={16} css={styles.left}>
            {showSearch && (
              <React.Fragment>
                <div css={styles.search}>
                  <ToSearchResultsLogo />
                  <SearchBar />
                </div>
                {/* tabs here */}
              </React.Fragment>
            )}
          </Col>

          {/* Login */}
          <Col span={8} css={styles.right}>
            <Typography>{prettifiedName(userState)}</Typography>

            {process.env.REACT_APP_AUTH0_LOGIN === 'true' ? (
              <Auth0Logout />
            ) : (
              <LocalLogout />
            )}
          </Col>
        </Row>
      </div>
    )
  );
}
