import React, { useContext, useEffect, useReducer } from 'react';
import { useLocalStorageState } from '../utils/saveHooks';
import { UserData, useGetUser } from './user';

type Action =
  | {
      type: 'update-user';
      payload: UserData;
    }
  | {
      type: 'restore-from-local-storage';
      payload: { data: UserData; keyPath: string[] };
    };
export type Dispatch = (action: Action) => void;
export type State = UserData | null;
type ProviderProps = { children: React.ReactNode };

const UserStateContext = React.createContext<State | undefined>(undefined);
const UserDispatchContext = React.createContext<Dispatch | undefined>(
  undefined
);

function userReducer(state: State, action: Action): State {
  if (action.type === 'update-user') {
    return action.payload ? action.payload : state;
  }

  if (action.type === 'restore-from-local-storage') {
    return action.payload.data ? action.payload.data : state;
  }

  return state;
}

export function useUserState(): State {
  const context = useContext(UserStateContext);
  if (context === undefined) {
    throw new Error('useUserState must be used inside UserContext');
  }
  return context;
}

export function useUserDispatch(): Dispatch {
  const context = useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error('useUserDispatch must be used inside UserContext');
  }
  return context;
}

export function UserProvider({ children }: ProviderProps): JSX.Element {
  const { request, data } = useGetUser();
  const [state, dispatch] = useReducer(userReducer, null);

  const { saveState } = useLocalStorageState({
    keyPath: ['user'],
    dispatch,
    onEmpty: () => {
      request({});
    },
  });

  useEffect(() => {
    const hasValidState = data && data.id !== '';
    if (hasValidState) {
      dispatch({ type: 'update-user', payload: data });
      saveState(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}
