/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { Empty, Form, Radio, Input, Button } from 'antd';
import { useJSTState, useJSTDispatch, Dispatch } from '../../api/JSTProvider';
import {
  SurveyResponse,
  updateJSTSession,
  getResults,
  IJSTQuestionResponse,
  IJSTQuestion,
  JSTQuestionID,
} from '../../api/jst';
import Immutable from 'immutable';
import { RadioChangeEvent } from 'antd/lib/radio';
import { equals } from 'ramda';

const styles = {
  formList: css`
    padding-left: 1rem !important;
    list-style: decimal !important;
  `,
  empty: css`
    max-width: 500px;
    margin-top: 2rem;
  `,
  question: css`
    margin: 2rem 0;
  `,
  lisatiedot: css`
    flex-direction: row !important;
    margin-top: -0.5rem;
    input {
      max-width: 500px;
      width: 100%;
    }
  `,
  primary: css`
    div.ant-form-item-label > label {
      font-weight: 500;
    }
  `,
  duration: css`
    margin-top: -6px;
    div.ant-form-item-label {
      padding-bottom: 0;
      & * {
        transition: color 0.5s;
      }
    }
  `,
  disabledDuration: css`
    margin-top: -6px;
    div.ant-form-item-label {
      padding-bottom: 0;
      & * {
        transition: color 0.5s;
        color: #d9d9d9;
      }
    }
  `,
  actions: css`
    margin-top: 3rem;
    margin-bottom: 3rem;
    button {
      margin-right: 1rem;
    }
  `,
};

type SurveyFieldProps = {
  value: string | SurveyResponse;
  label?: string;
  onChange: (event: any) => void;
  disabled?: boolean;
};

const DURATION_OPTIONS = [
  'alle 2t',
  'alle 1vrk',
  '1-3vrk',
  '4-7vrk',
  '1-4vk',
  'yli 1kk',
];

// Memoization equality checker
const surveyFieldValueIsUnchanged = (
  prevProps: SurveyFieldProps,
  nextProps: SurveyFieldProps
) =>
  prevProps.value === nextProps.value &&
  prevProps.disabled === nextProps.disabled;

// Main question with possible dropdown
const SurveyPrimaryRadioGroup = React.memo(
  ({ value, onChange, label }: SurveyFieldProps) => {
    return (
      <Form.Item css={styles.primary} label={label}>
        <Radio.Group value={value} onChange={onChange}>
          <Radio.Button value={1}>Kyllä</Radio.Button>
          <Radio.Button value={-1}>Ei</Radio.Button>
          <Radio.Button value={0}>En tiedä</Radio.Button>
        </Radio.Group>
      </Form.Item>
    );
  },
  surveyFieldValueIsUnchanged
);

// Oireilun kesto
const SurveyOptionalDuration = React.memo(
  ({ value, onChange, disabled }: SurveyFieldProps) => {
    return (
      <Form.Item
        css={disabled ? styles.disabledDuration : styles.duration}
        label="Oireilun kesto"
      >
        <Radio.Group
          disabled={disabled}
          value={value}
          onChange={onChange}
          size="small"
        >
          {DURATION_OPTIONS.map((option) => (
            <Radio.Button key={option} value={option}>
              {option}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  },
  surveyFieldValueIsUnchanged
);

// Lisätiedot-field
const SurveyOptionalMoreInfo = React.memo(
  ({ value, disabled, onChange }: SurveyFieldProps) => {
    return (
      <Form.Item css={styles.lisatiedot}>
        <Input
          disabled={disabled}
          placeholder="Lisätiedot"
          value={value}
          onChange={onChange}
        />
      </Form.Item>
    );
  },
  surveyFieldValueIsUnchanged
);

// Update / insert latest changes into responses state
function upsert(
  dispatch: Dispatch,
  questionId: string,
  paramsHandler: (event: any) => Record<string, any>
) {
  return function (event: any) {
    dispatch({
      type: 'upsert-survey-response',
      payload: {
        _id: questionId,
        ...paramsHandler(event),
      },
    });
  };
}

function showOptionalFields(
  responses: Immutable.Map<string, Immutable.Record<IJSTQuestionResponse>>,
  question: Immutable.Record<IJSTQuestion>
) {
  return responses.getIn([question.get('_id'), 'vastaus']) !== undefined;
}

function showOptionalDurationField(
  question: Immutable.Record<IJSTQuestion>,
  responses: Immutable.Map<string, Immutable.Record<IJSTQuestionResponse>>
) {
  const response = responses.getIn([question.get('_id'), 'vastaus']);
  return response === 1 && question.get('eiKesto') === null;
}

function scrollDown(elt: any) {
  const yDiff = elt.getBoundingClientRect().top;
  const viewportHeight = window.innerHeight;
  if (yDiff > 0.5 * viewportHeight) {
    window.scrollBy({
      top: yDiff - 0.5 * viewportHeight,
      behavior: 'smooth',
    });
  }
}

function shouldUpdateResults(
  current: Immutable.Map<JSTQuestionID, Immutable.Record<IJSTQuestionResponse>>,
  previous: Immutable.Map<
    JSTQuestionID,
    Immutable.Record<IJSTQuestionResponse>
  >,
  loading: string | false
) {
  if (loading === 'updating-results') return false;

  const currSansLisatieto = current.map((r) => r.delete('lisatieto'));
  const prevSansLisatieto = previous.map((r) => r.delete('lisatieto'));
  return !equals(currSansLisatieto, prevSansLisatieto);
}

export default function Survey(): JSX.Element {
  const state = useJSTState();
  const session = state.get('session');
  const questions = state.get('questions');
  const responses = state.get('responses');
  const previousResponses = state.get('previousResultsResponses');
  const loading = state.get('loading');
  const description = state.get('description');
  const dispatch = useJSTDispatch();
  const upsertWithEvent = (
    question: Immutable.Record<IJSTQuestion>,
    handler: (event: any) => any
  ) => upsert(dispatch, question.get('_id'), handler);

  useEffect(() => {
    if (description === 'auto-survey-update') {
      updateJSTSession({
        session,
        questions,
        responses,
        dispatch,
        fetchResultsOn404: false,
      });
      dispatch({ type: 'reset-description' });
    }

    if (shouldUpdateResults(responses, previousResponses, loading)) {
      getResults({ session, questions, responses, dispatch, noRedirect: true });
    }
  }, [
    description,
    dispatch,
    questions,
    responses,
    previousResponses,
    session,
    loading,
  ]);

  if (!questions || (questions.isEmpty && questions?.isEmpty()))
    return <Empty css={styles.empty} description="Ei oirekyselyä" />;

  return (
    <React.Fragment>
      <Form layout="vertical">
        <ol css={styles.formList}>
          {questions.map((question, idx) => (
            <li css={styles.question} key={idx}>
              {/* Main question */}
              <SurveyPrimaryRadioGroup
                value={responses.getIn([question.get('_id'), 'vastaus'])}
                label={question.toJS().kysymys.fi.kysymys}
                onChange={upsertWithEvent(
                  question,
                  (event: RadioChangeEvent) => {
                    scrollDown(event.nativeEvent.target);
                    return { vastaus: event.target.value, kesto: undefined };
                  }
                )}
              />
              {/* Symptoms' duration (optional) */}
              <SurveyOptionalDuration
                disabled={
                  !showOptionalFields(responses, question) ||
                  !showOptionalDurationField(question, responses)
                }
                value={responses.getIn([question.get('_id'), 'kesto'])}
                onChange={upsertWithEvent(question, (event: any) => ({
                  kesto: event.target.value,
                }))}
              />
              {/* Lisätiedot-field (optional) */}
              <SurveyOptionalMoreInfo
                disabled={!showOptionalFields(responses, question)}
                value={
                  responses.getIn([question.get('_id'), 'lisatieto']) || ''
                }
                onChange={upsertWithEvent(question, (event: any) => ({
                  lisatieto: event.target.value,
                }))}
              />
            </li>
          ))}
        </ol>
      </Form>
      <div css={styles.actions}>
        <Button
          type="primary"
          onClick={() =>
            updateJSTSession({
              session,
              questions,
              responses,
              dispatch,
              fetchResultsOn404: true,
            })
          }
          disabled={description !== 'allow-survey-update'}
        >
          Tarkista lisäkysymykset
        </Button>

        <Button
          type="primary"
          onClick={() =>
            getResults({ session, questions, responses, dispatch })
          }
        >
          Näytä yhteenveto
        </Button>
      </div>
    </React.Fragment>
  );
}
