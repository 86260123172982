import { useState, useEffect, useCallback } from 'react';
import paths from '../paths';
import queryString from 'query-string';
import { icons } from '../common/assets';
import { handleFetch } from '../common/fetch';
import { Dispatch as JSTDispatch } from './JSTProvider';
import { Dispatch as SearchDispatch } from './SearchProvider';

// --- types ---

export type AutocompleteResults = {
  history: {
    id: number;
    query: string;
    type: string;
  }[];
  direct: {
    query: string;
    type: string;
  }[];
  proposals: string[];
};

export type SearchResult = {
  title: string;
  type: string;
  description?: string;
  proposals?: string[];
  url?: string;
  id?: string;
  modifiedAt?: string;
};

export type SearchQueryParams = {
  query?: string;
  order?: string;
  type?: string;
  page?: number;
  groupResults?: boolean;
};

export type LinkType = {
  title: string;
  icon: null | string;
  id?: string;
  value: '' | 'chatbot' | 'symptom' | 'terveysportti' | 'document' | 'support';
  toLink?: (m: Record<string, string | undefined>) => string;
};

// --- consts ---

export const LINK_TYPES: LinkType[] = [
  { title: 'Kaikki', icon: null, value: '' },
  {
    title: 'Chatbot',
    icon: icons.chatbot,
    value: 'chatbot',
    toLink: ({ query, attr, url, id }: Record<string, string | undefined>) =>
      `${paths.bot}?symptoms=${attr}&query=${query}`,
  },
  {
    title: 'Oirekysely',
    icon: icons.oirekysely,
    value: 'symptom',
    toLink: ({ query, attr, url, id }: Record<string, string | undefined>) =>
      `${paths.symptom}?query=${query}&symptoms=${attr}`,
  },
  {
    title: 'Terveysportti',
    icon: icons.terveysportti,
    value: 'terveysportti',
    toLink: ({ query, attr, url, id }: Record<string, string | undefined>) =>
      `${paths.terveysportti}${url}`,
  },
  {
    title: 'Dokumentit',
    icon: icons.dokumentti,
    value: 'document',
    toLink: ({ query, attr, url, id }: Record<string, string | undefined>) =>
      `${paths.document}?name=${id}&query=${query}`,
  },
  { title: 'Yhteisötuki', icon: icons.yhteisotuki, value: 'support' },
];

// --- endpoints ---

const endpoint = '/api/v1/search';

const autocompleteEndpoint = (query: string) =>
  `${endpoint}/autocomplete?query=${query}`;

// Query string components for endpoint
const paramStrings = ({
  query,
  order,
  type,
  page,
  groupResults,
}: SearchQueryParams) => {
  return {
    queryStr: query ? `query=${query}` : '',
    orderStr: order ? `order=${order}` : '',
    typeStr: type ? `&type=${type}` : '',
    pageStr: `&page=${page || 1}`,
    groupResultsStr:
      groupResults !== undefined
        ? `&groupResults=${groupResults}`
        : `&groupResults=true`,
  };
};

export const fullSearchEndpoint = (queryParams: SearchQueryParams): string => {
  const { queryStr, typeStr, pageStr, groupResultsStr } = paramStrings(
    queryParams
  );
  return `${endpoint}/full?${queryStr}${typeStr}${pageStr}${groupResultsStr}`;
};

export const listSearchEndpoint = (queryParams: SearchQueryParams): string => {
  const { orderStr, typeStr, pageStr, groupResultsStr } = paramStrings(
    queryParams
  );
  return `${endpoint}/list?${orderStr}${typeStr}${pageStr}${groupResultsStr}`;
};
// --- utils ---

export const getLinkTypeObject = (type: string): LinkType | null => {
  for (const linkType of LINK_TYPES) {
    if (linkType.value === type) return linkType;
  }
  return null;
};

export const resultsPageLink = (queryParams: SearchQueryParams): string => {
  const { queryStr, typeStr, pageStr } = paramStrings(queryParams);
  return `${paths.results}?${queryStr}${typeStr}${pageStr}`;
};

// Parse query parameters for search from URL
export function getSearchParams(search: string): SearchQueryParams {
  const parsed = queryString.parse(search);

  return {
    query: typeof parsed.query === 'string' ? parsed.query : '',
    type: typeof parsed.type === 'string' ? parsed.type : '',
    page: typeof parsed.page === 'string' ? parseInt(parsed.page) : 1,
  };
}

// --- hooks ---

export function useSearchAutocomplete(onUpdate?: () => void) {
  const [query, setQuery] = useState<null | string>(null);
  const [error, setError] = useState<null | string>(null);
  const [results, setResults] = useState<null | AutocompleteResults>(null);

  useEffect(() => {
    let unmounted = false;

    if (query) {
      handleFetch({
        endpoint: autocompleteEndpoint(query),
        fetchOptions: { method: 'GET' },
        onSuccess: (data) => {
          setResults(data);
          if (onUpdate) onUpdate();
        },
        onError: (error) => {
          if (!unmounted) setError('Pahoittelut, haku ei onnistunut.');
        },
      });

      // reset query
      setQuery(null);
    }

    return () => {
      unmounted = true;
    };
  }, [onUpdate, query]);

  return { results, error, autocomplete: setQuery };
}

export function useSearchFull(
  dispatch: SearchDispatch | JSTDispatch,
  listSearch = false
): {
  search: (queryParams: SearchQueryParams) => void;
} {
  const [queryParams, setQueryParams] = useState<null | SearchQueryParams>(
    null
  );
  // const [error, setError] = useState<null | string>(null);
  // const [results, setResults] = useState<null | SearchResult[]>(null);

  useEffect(() => {
    let unmounted = false;

    if (queryParams) {
      dispatch({ type: 'fetching-search-results' });
      handleFetch({
        endpoint: listSearch
          ? listSearchEndpoint(queryParams)
          : fullSearchEndpoint(queryParams),
        fetchOptions: { method: 'GET' },
        onSuccess: (data) => {
          if (!unmounted) {
            dispatch({ type: 'update-search-results', payload: data });
            if (listSearch) {
              dispatch({ type: 'set-is-newest-results', payload: true });
            } else {
              dispatch({ type: 'set-is-newest-results', payload: false });
            }
          }
        },
        onError: (error) => {
          if (!unmounted)
            dispatch({
              type: 'set-error',
              payload: {
                message: 'Pahoittelut, haku ei onnistunut.',
                verbose: error,
              },
            });
        },
      });
    }

    return () => {
      unmounted = true;
    };
  }, [dispatch, queryParams, listSearch]);

  // Note: React guarantees that setQueryParams function identity is stable and
  // won’t change on re-renders. This is why it’s safe to omit from the
  // useCallback dependency list.
  const search = useCallback(
    (queryParams: SearchQueryParams) => setQueryParams(queryParams),
    []
  );

  return {
    // results,
    // error,
    search,
  };
}
