/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { kuuraLogo } from '../../common/assets';
import BotMessageText from './BotMessageText';
import { Question } from '../../api/getQuestion';
import Immutable from 'immutable';

const styles = {
  root: css`
    width: 100%;
  `,
  avatarHeading: css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
    & span {
      margin-left: 0.5rem;
      font-size: 16px;
      color: #999;
    }
    & img {
      flex-grow: 0;
      flex-shrink: 1;
      flex-basis: auto;
      width: auto;
      height: 20px;
    }
  `,
  // TODO: add missing media-query
  texts: css`
    max-width: 500px;
    font-size: 16px;
    & > div.bot-message-text {
      margin-bottom: 1rem;
    }
    & img.emoji {
      height: 20px;
      display: inline;
    }
  `,
};

type MessageProps = {
  messages: Immutable.List<Immutable.Record<Question>>;
};

export default function BotMessage({ messages }: MessageProps): JSX.Element {
  return (
    <div className="bot-message-texts" css={styles.root}>
      <div css={styles.avatarHeading}>
        <img src={kuuraLogo} alt="Kuura logo" />
        <span>ATP</span>
      </div>
      <div css={styles.texts}>
        {messages.map((message, idx) => (
          <BotMessageText key={idx} message={message} />
        ))}
      </div>
    </div>
  );
}
