/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import Immutable from 'immutable';
import { AnswerOption, QuestionData } from '../../api/getQuestion';
import { Input, InputNumber } from 'antd';
import { getNextMessage, Dispatch } from '../../api/BotProvider';
import { AnswerButton } from './AnswerButton';
import { useState } from 'react';

const { TextArea } = Input;

const styles = {
  root: css`
    width: 100%;
    margin: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
      border-bottom: 1px solid #40a9ff;
    }
  `,
  textArea: css`
    resize: none;
    width: 80%;
    font-size: 16px;
    min-height: 1rem;
    margin: 0;
    border: none;
    &:hover,
    &:focus {
      border: none;
      box-shadow: none;
    }
  `,
  sendButton: css`
    height: max-content;
    margin-left: 0.5rem;
    padding: 0.5rem;
  `,
};

type Option = Immutable.Record<AnswerOption>;
type Message = Immutable.Record<QuestionData>;

type AnswerButtonProps = {
  options: Immutable.List<Option>;
  dispatch: Dispatch;
  currentAnswer: undefined | Option;
  index: number;
  message: Message;
};

export function AnswerInput({
  options,
  dispatch,
  currentAnswer,
  index,
  message,
}: AnswerButtonProps): JSX.Element {
  const [input, setInput] = useState<number | string>('');

  // If answered -> show button instead
  if (currentAnswer !== undefined) {
    return (
      <AnswerButton
        option={currentAnswer}
        dispatch={dispatch}
        currentAnswer={currentAnswer}
        index={index}
        message={message}
      />
    );
  }

  return (
    <div css={styles.root}>
      {options.getIn([0, 'type']) === 'number' ? (
        <InputNumber
          placeholder="Vastauksesi"
          value={typeof input === 'number' ? input : parseInt(input)}
          onChange={(value) => setInput(value || '')}
          css={styles.textArea}
        />
      ) : (
        <TextArea
          autoSize={{ minRows: 1 }}
          placeholder="Vastauksesi"
          value={input}
          onChange={(event) => setInput(event.target.value)}
          css={styles.textArea}
        />
      )}
      {options.map((option, idx) => (
        <AnswerButton
          key={idx}
          option={option}
          dispatch={dispatch}
          currentAnswer={currentAnswer}
          index={index}
          message={message}
          onClick={() => {
            const answeredOption = option.set(
              'ansValue',
              typeof input === 'string' ? input : `${input}`
            );
            dispatch({
              type: 'upsert-answer',
              payload: { index: index, answer: answeredOption },
            });
            getNextMessage(dispatch, message, answeredOption);
          }}
        />
      ))}
    </div>
  );
}
