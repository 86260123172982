/** @jsx jsx */
import { css, jsx, SerializedStyles } from '@emotion/core';
import {
  useDocumentDispatch,
  useDocumentState,
} from '../../api/DocumentProvider';
import { Empty } from 'antd';
import { useState } from 'react';
import {
  DownloadOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import Spinner from '../../components/Spinner';
import { Document, Page } from 'react-pdf';
import { getDocumentFile } from '../../api/document';

const styles = {
  root: css`
    padding: 0 2.5rem;
  `,
  message: css`
    max-width: 860px;
    width: 100%;
  `,
  breadcrumbs: css`
    margin-bottom: 1.5rem;
  `,
  row: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  `,
  column: css`
    display: flex;
    flex-direction: column;
    max-width: 600px;
  `,
  leftcolumn: css`
    display: flex;
    flex-direction: column;
    width: 200px;
  `,
  button: css`
    width: auto;
    padding-left: 5px;
    padding-right: 5px;
    border: none;
    background: initial;
    outline: none;
  `,
  download_icon: css`
    font-size: 22px;
    cursor: pointer;
    color: black;
    margin-left: 5px;
    margin-top: -2px;
  `,
  zoom_icon: css`
    font-size: 28px;
    cursor: pointer;
    color: black;
    margin-left: 5px;
    margin-top: -2px;
  `,
  container: css`
    flex-flow: column wrap;
    max-width: 800px;
    line-height: 26px;
  `,
  previewmargin: css`
    margin-top: 26px;
    margin-bottom: 26px;
  `,
  pdfpreview: css`
    width: fit-content;
  `,
  pdfpage: css`
    width: auto;
    border: 2px;
    margin-top: 15px;
    margin-bottom: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  `,
  bold: css`
    font-weight: bold;
  `,
  downloadlink: css`
    cursor: pointer;
    color: blue;
    text-decoration: underline;
  `,
  image: css`
    max-width: 800px;
    max-height: 800px;
  `,
  sticky: css`
    width: 230px;
    background: white;
    position: sticky;
    top: 10px;
    z-index: 1000;
    font-size: 26px;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  `,
};

type Rowcolumn = {
  text: string;
  dataValue?: string | undefined;
  button?: any;
  style?: SerializedStyles;
  downloadlink?: boolean;
};

export default function DocumentPage(): JSX.Element {
  const {
    loading,
    documentData,
    documentFile,
    documentPreview,
  } = useDocumentState();
  const [url, setUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [pageScale, setPageScale] = useState(1.25);
  const dispatch = useDocumentDispatch();

  function zoomIn() {
    setPageScale(pageScale + 0.25);
  }

  function zoomOut() {
    if (pageScale > 0.25) {
      setPageScale(pageScale - 0.25);
    }
  }
  function zoomReset() {
    setPageScale(1.0);
  }

  const reader = new FileReader();
  reader.addEventListener('load', () => {
    if (typeof reader.result === 'string') {
      setUrl(reader.result);
    }
  });
  if (documentPreview !== undefined) {
    reader.readAsDataURL(documentPreview);
  }
  const download = () => {
    let downloadUrl = '';
    if (documentFile !== undefined) {
      downloadUrl = URL.createObjectURL(documentFile);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = documentFile?.name || 'file';
      link.click();
      URL.revokeObjectURL(downloadUrl);
    } else {
      if (documentData?.name) {
        getDocumentFile(documentData?.name).then((res) => {
          dispatch({ type: 'set-file', payload: res });
          downloadUrl = URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.download = res.name;
          link.click();
          URL.revokeObjectURL(downloadUrl);
        });
      }
    }
  };

  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  const RowColumn = ({
    text,
    dataValue,
    button,
    style,
    downloadlink = false,
  }: Rowcolumn) => {
    return (
      <div css={styles.row}>
        <div css={styles.leftcolumn}>
          <span>{text}</span>
        </div>
        <div css={styles.column}>
          {downloadlink ? (
            <span css={style} onClick={download}>
              {dataValue}
              {button}
            </span>
          ) : (
            <span css={style}>
              {dataValue} {button}
            </span>
          )}
        </div>
      </div>
    );
  };

  const DownloadButton = () => {
    return (
      <button css={styles.button}>
        <DownloadOutlined css={styles.download_icon} />
      </button>
    );
  };
  return (
    <div css={styles.root}>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div>
          {documentData ? (
            <div>
              <div css={styles.container}>
                <RowColumn
                  text="Otsikko: "
                  dataValue={documentData?.title}
                  style={styles.bold}
                />
                <RowColumn
                  text="Hakusanat: "
                  dataValue={documentData?.keywords.join(', ')}
                />
                <RowColumn
                  text="Viimeisin muokkaus: "
                  dataValue={documentData?.lastEditDate.toString()}
                />
                <RowColumn
                  text="Muokkaaja: "
                  dataValue={documentData?.lastEditUser}
                />
                <RowColumn
                  text="Tiedostonimi: "
                  dataValue={documentData?.filename}
                  button={<DownloadButton />}
                  style={styles.downloadlink}
                  downloadlink={true}
                />
              </div>
              <div css={styles.previewmargin}>DOKUMENTIN ESIKATSELU</div>

              {documentPreview?.type === 'application/pdf' && (
                <div>
                  <div css={styles.sticky}>
                    <button css={styles.button} onClick={zoomOut}>
                      <ZoomOutOutlined css={styles.zoom_icon} />
                    </button>{' '}
                    {pageScale * 100}%{' '}
                    <button css={styles.button} onClick={zoomIn}>
                      <ZoomInOutlined css={styles.zoom_icon} />
                    </button>{' '}
                    <button css={styles.button} onClick={zoomReset}>
                      <ReloadOutlined css={styles.zoom_icon} />
                    </button>
                  </div>
                  <Document
                    css={styles.pdfpreview}
                    file={url}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={<Spinner />}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page
                        css={styles.pdfpage}
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        renderAnnotationLayer={false}
                        scale={pageScale}
                      />
                    ))}
                  </Document>
                </div>
              )}
              {documentPreview?.type.includes('image/') && (
                <div>
                  <img
                    css={styles.image}
                    src={url}
                    alt={documentPreview?.name}
                  />
                </div>
              )}
              {!documentPreview?.type.includes('image/') &&
                documentPreview?.type !== 'application/pdf' && (
                  <div>Esikatselua ei saatavilla.</div>
                )}
            </div>
          ) : (
            <div>
              <Empty
                description="Dokumenttia ei löytynyt."
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
