/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useState, useEffect, useMemo } from 'react';
import { Input, AutoComplete } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import {
  useSearchAutocomplete,
  AutocompleteResults,
  getLinkTypeObject,
} from '../api/search';
import { useSearchState, useSearchDispatch } from '../api/SearchProvider';
import { makeTabPayload, useTabDispatch, useTabState } from './TabProvider';
import { useLocalStorageState } from '../utils/saveHooks';

const styles = {
  autocomplete: css`
    width: 100%;
    max-width: 500px;
  `,
  option: css`
    display: flex;
    align-items: center;
    img {
      max-height: 16px;
      width: auto;
    }
  `,
  optionIcon: css`
    margin-right: 0.5rem;
  `,
};

// --- autocomplete options ---

type RenderableOption =
  | {
      index: number;
      type: 'direct';
      item: { query: string; type: string };
    }
  | { index: number; type: 'proposal'; item: string };

function getIcon(type?: string) {
  const linkData = type !== undefined && getLinkTypeObject(type);
  const icon =
    linkData && linkData?.icon ? (
      <img src={linkData.icon} alt={linkData.title} />
    ) : (
      <SearchOutlined />
    );
  return icon;
}

// These two functions (titleFromValue and typeFromValue) are hacks to work around a bug in Ant Design's Autocomplete (and Select) components,
// that clobbers the `key` prop for each option with its `value` prop.

function titleFromValue(value: string) {
  const splitting = value.split('/');
  return splitting[splitting.length - 1];
}

function typeFromValue(value: string) {
  const splitting = value.split('/');
  return splitting[splitting.length - 2];
}

function renderOption({ type, item, index }: RenderableOption) {
  let title, icon, value;

  // For search proposals
  if (typeof item === 'string') {
    title = item;
    icon = getIcon();
    value = `${index}/${type}/${title}`;
  }
  // For direct links
  else {
    title = item.query;
    icon = getIcon(item.type);
    value = `${index}/${item.type}/${title}`;
  }

  return {
    value,
    label: (
      <div css={styles.option}>
        <span css={styles.optionIcon}>{icon}</span>
        {title}
      </div>
    ),
  };
}

function makeAutocompleteOptions({ proposals, direct }: AutocompleteResults) {
  return [
    {
      label: 'Suoralinkit',
      options: direct.map((item, idx) =>
        renderOption({ type: 'direct', item, index: idx })
      ),
    },
    {
      label: 'Hakuehdotukset',
      options: proposals.map((item, idx) =>
        renderOption({ type: 'proposal', item, index: idx })
      ),
    },
  ];
}

function debounce(f: (...args: any) => void, wait?: number) {
  let timeout: number | undefined;

  return (...args: any[]) => {
    const deferred = () => {
      timeout = undefined;
      f(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(deferred, wait);
  };
}

// --- actual search bar component ---

export default function SearchBar(): JSX.Element {
  const searchState = useSearchState();
  const searchDispatch = useSearchDispatch();
  const [waitingAutocomplete, setWaitingAutocomplete] = useState(false);
  const tabState = useTabState();
  const tabDispatch = useTabDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { saveState } = useLocalStorageState({
    keyPath: ['search'],
    dispatch: searchDispatch,
    callback: (data) => autocomplete(titleFromValue(data.input)),
    onEmpty: () => {
      if (searchState.input !== '') {
        autocomplete(titleFromValue(searchState.input));
      }
    },
  });

  const { results, autocomplete } = useSearchAutocomplete(() =>
    setWaitingAutocomplete(false)
  );
  const options = (searchState.input && results) || {
    proposals: [],
    direct: [],
    history: [],
  };

  const deferredAutocomplete = useMemo(
    () => debounce((q) => autocomplete(titleFromValue(q)), 1000),
    [autocomplete]
  );

  // update localStorage on results change and set loading false if input is empty
  useEffect(() => {
    if (searchState.input === '') {
      setWaitingAutocomplete(false);
    }
    saveState(searchState);
  }, [saveState, searchState]);

  // Handle search bar "submit" events
  const searchSubmit = (query: string, type?: string) => {
    setDropdownOpen(false);
    searchDispatch({ type: 'update-search-query', payload: { query } });
    tabDispatch({ type: 'change-tab', payload: '0' });

    if (type === 'terveysportti') {
      const linkData = getLinkTypeObject(type);
      if (linkData?.toLink) {
        window.open(linkData.toLink({ query, attr: query.toLowerCase() }));
      }
      return;
    }

    if (type === 'chatbot' || type === 'symptom') {
      const linkData = getLinkTypeObject(type);
      const tabPayload = makeTabPayload({
        attr: query,
        type,
        icon: linkData?.icon || '',
        key: `${parseInt(tabState.panes[tabState.panes.length - 1].key) + 1}`,
      });
      if (tabPayload) {
        tabDispatch({ type: 'open-tab', payload: tabPayload });
      }
      return;
    }
  };

  return (
    <AutoComplete
      css={styles.autocomplete}
      options={makeAutocompleteOptions(options)}
      backfill={false}
      value={searchState.input}
      open={dropdownOpen}
      onChange={(query: string) => {
        searchDispatch({
          type: 'update-search-input',
          payload: titleFromValue(query),
        });
        // If query is not empty dropdown should be shown
        if (query) setDropdownOpen(true);
      }}
      onSearch={(query: string) => {
        setWaitingAutocomplete(true);
        deferredAutocomplete(query);
      }}
      onSelect={(query: string) =>
        searchSubmit(titleFromValue(query), typeFromValue(query))
      }
      onFocus={() => {
        setDropdownOpen(true);
      }}
      onBlur={() => {
        setDropdownOpen(false);
      }}
    >
      <Input.Search
        size="large"
        placeholder={'Aloita kirjoittamalla hakusana tai kysymys tähän...'}
        allowClear
        onPressEnter={() => searchSubmit(searchState.input)}
        onSearch={(query, e) => {
          if (query) {
            searchSubmit(searchState.input);
          }
          if (!query) {
            searchDispatch({
              type: 'update-search-query',
              payload: { query: '' },
            });
            searchDispatch({
              type: 'update-search-results',
              payload: undefined,
            });
            saveState(searchState);
            setDropdownOpen(false);
            setTimeout(() => e?.currentTarget.blur(), 0);
          }
        }}
        loading={waitingAutocomplete}
      />
    </AutoComplete>
  );
}
