/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { createPane, useTabDispatch, useTabState } from './TabProvider';
import { deleteFromStorage, useLocalStorageState } from '../utils/saveHooks';

const { TabPane } = Tabs;

const styles = {
  root: css`
    margin-top: 0.5rem;
    overflow: visible;
    & .ant-tabs-nav {
      padding: 0 2rem;
    }
  `,
  paneTitle: css`
    display: flex;
    align-items: center;
    & img {
      margin-right: 0.5rem;
      max-height: 1rem;
    }
  `,
};

function shortenTitle(title: string) {
  const st = title.substr(0, 20);
  if (st === title) return title;
  return st + '...';
}

export default function TabbedContent(): JSX.Element {
  const state = useTabState();
  const dispatch = useTabDispatch();
  const { saveState } = useLocalStorageState({ keyPath: ['tabs'], dispatch });

  useEffect(() => {
    saveState(state);
  }, [saveState, state]);

  return !state ? (
    <React.Fragment />
  ) : (
    <Tabs
      hideAdd
      onChange={(key) => dispatch({ type: 'change-tab', payload: key })}
      activeKey={state.activeKey}
      type="editable-card"
      onEdit={(targetKey, action) => {
        if (action === 'remove') {
          dispatch({ type: 'close-tab', payload: targetKey });
          deleteFromStorage(['tab-provider-states', targetKey.toString()]);
        }
      }}
      css={styles.root}
    >
      {state.panes.map((pane) => {
        const title = (
          <div css={styles.paneTitle}>
            {pane.icon && <img src={pane.icon} alt="tab icon" />}
            {pane.key === state.activeKey
              ? pane.title
              : shortenTitle(pane.title)}
          </div>
        );
        return (
          <TabPane tab={title} key={pane.key} closable={pane.closable}>
            {createPane(state, dispatch, pane)}
          </TabPane>
        );
      })}
    </Tabs>
  );
}
