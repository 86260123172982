const paths = {
  root: '/',
  search: '/',
  results: '/search', // TODO: change to searchResults
  login: '/login',
  symptom: '/symptom',
  symptomResults: '/symptom/results',
  bot: '/bot',
  terveysportti: 'https://www.terveysportti.fi/apps/ltk/article/',
  document: '/document',
};

export default paths;
