import React from 'react';
import { JSTProvider } from '../../api/JSTProvider';
import { useTabState } from '../../components/TabProvider';
import SymptomPage from '../SymptomPage';
import SymptomResults from '../SymptomResultsPage';

export default function SymptomsTab({
  tabKey,
  defaultSymptoms,
  useLocalStorageProviderState,
}: {
  tabKey: any;
  defaultSymptoms: string[];
  useLocalStorageProviderState: ({
    dispatch,
    callback,
    onEmpty,
  }: {
    dispatch: (action: any) => void;
    callback?: (data: any) => void;
    onEmpty?: () => void;
  }) => Record<string, any>;
}): JSX.Element {
  const tabState = useTabState().panes.find((pane) => pane.key === tabKey);

  return !tabState ? (
    <React.Fragment />
  ) : (
    <JSTProvider
      defaultSymptoms={defaultSymptoms}
      useLocalStorageProviderState={useLocalStorageProviderState}
    >
      {tabState.state.which === 'symptoms-page' ? (
        <SymptomPage tabState={tabState} defaultSymptoms={defaultSymptoms} />
      ) : (
        <SymptomResults tabState={tabState} />
      )}
    </JSTProvider>
  );
}
