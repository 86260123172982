import { request, requestFile } from './fetchUtils';

export type UrlParams = {
  [param: string]: string | undefined;
};

export function get<T>(url: string, params?: UrlParams): Promise<T> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'GET',
  });
}

export function getFile(url: string, params?: UrlParams): Promise<File> {
  return requestFile(mergeUrlWithParams(url, params), {
    method: 'GET',
  });
}

export function post<T>(
  url: string,
  data?: any,
  params?: UrlParams
): Promise<T> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'POST',
    body: transformBody(data),
  });
}

export function patch<T>(
  url: string,
  data?: any,
  params?: UrlParams
): Promise<T> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'PATCH',
    body: transformBody(data),
  });
}

export function put<T>(
  url: string,
  data?: any,
  params?: UrlParams
): Promise<T> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'PUT',
    body: transformBody(data),
  });
}

export function del<T>(url: string, params?: UrlParams): Promise<T> {
  return request<T>(mergeUrlWithParams(url, params), {
    method: 'DELETE',
  });
}

function transformBody(data: any): string {
  return JSON.stringify(data);
}

function mergeUrlWithParams(url: string, params?: UrlParams): string {
  if (!params) {
    return encodeURI(url);
  } else {
    const parameters: string[] = [];
    for (const key in params) {
      if (params[key]) {
        parameters.push(key + '=' + params[key]);
      }
    }
    const fullUrl = url + '?' + parameters.join('&');
    return encodeURI(fullUrl);
  }
}
