// this comment tells babel to convert jsx to calls to a function called jsx instead of React.createElement
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { useEffect } from 'react';
import { notification, Typography, Row, Col, Divider } from 'antd';
import { useJSTKeywords } from '../../api/jst';
import { useJSTDispatch, useJSTState } from '../../api/JSTProvider';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import SymptomSelect from './SymptomSelect';
import Survey from './Survey';
import { List } from 'immutable';
import SymptomResults from '../SymptomResultsPage';
import { useTabDispatch } from '../../components/TabProvider';

const styles = {
  root: css`
    padding: 0 2.5rem;
  `,
  helpText: css`
    margin-bottom: 1rem;
    white-space: pre-line;
  `,
  loadingSkeleton: css`
    max-width: 600px;
  `,
  breadcrumbs: css`
    margin-bottom: 1rem;
  `,
};

export default function SymptomPage({
  defaultSymptoms,
  tabState,
}: {
  defaultSymptoms: string[];
  tabState: any;
}): JSX.Element {
  const state = useJSTState();
  const error = state.get('error');
  const loading = state.get('loading');
  const description = state.get('description');
  const lastSymptomsSearch = state.get('lastSymptomsSearch');
  const symptoms = state.get('symptoms');
  const age = state.get('age');
  const gender = state.get('gender');

  const { keywords, loading: loadingSelect } = useJSTKeywords();
  const jstDispatch = useJSTDispatch();
  const tabDispatch = useTabDispatch();

  // Handle error notifications
  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Yhteysongelmia',
        description: error,
        placement: 'bottomLeft',
      });
      jstDispatch({ type: 'set-error', payload: undefined });
    }
  }, [jstDispatch, error]);

  // Handle redirect with state passing
  useEffect(() => {
    if (description === 'finished-survey' && tabState) {
      tabDispatch({
        type: 'update-tab-state',
        payload: {
          key: tabState.key,
          state: { ...(tabState.state || {}), which: 'symptom-results-page' },
        },
      });
    }
  }, [description, tabDispatch, tabState]);

  // Persist state in localStorage every second
  // useInterval(() => setStateLocalStorage(state.toJS()), 1000);

  return (
    <div css={styles.root}>
      <Typography css={styles.helpText}>
        {/* As JS string to include newline */}
        {`Valitse potilaan ikäryhmä, sukupuoli (valinnainen) ja kirjoita potilaan oireet alla olevaan kenttään.
          Oirekysely päivittyy kun olet painanut "Päivitä kysymykset" nappia.`}
      </Typography>
      <SymptomSelect
        symptoms={symptoms.count() === 0 ? List(defaultSymptoms) : symptoms}
        age={age}
        gender={gender}
        lastSymptomsSearch={lastSymptomsSearch}
        dispatch={jstDispatch}
        keywords={keywords}
        loading={loadingSelect}
      />
      {loading === 'fetching-data' ? (
        <LoadingSkeleton css={styles.loadingSkeleton} hideHeading />
      ) : (
        <div>
          <Divider />
          <Row>
            <Col span={12}>
              <Survey />
            </Col>
            <Col span={12}>
              <SymptomResults short />
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}
