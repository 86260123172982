/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Skeleton } from 'antd';

const styles = {
  headingSkeleton: css`
    margin-bottom: 1.5rem;
    max-width: 300px !important;
  `,
};

export default function LoadingSkeleton({
  hideHeading,
  ...props
}: {
  hideHeading?: boolean;
  props?: any;
}): JSX.Element {
  return (
    <div {...props}>
      {!hideHeading && (
        <Skeleton css={styles.headingSkeleton} paragraph={false} />
      )}
      <Skeleton paragraph={{ rows: 3 }} />
      <Skeleton paragraph={{ rows: 3 }} />
      <Skeleton paragraph={{ rows: 3 }} />
    </div>
  );
}
