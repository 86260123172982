import { compose, assocPath, path, dissocPath } from 'ramda';
import { useEffect } from 'react';

type FieldsToState = Record<string, (item: any) => any>;

type LoadParams = {
  keyPath: string[];
  dispatch: (action: any) => void;
  fieldsToState?: FieldsToState;
  callback?: (data: any) => void;
  onEmpty?: () => void;
};

function getEntireStateFromStorage(): Record<string, any> {
  return JSON.parse(localStorage.getItem('triaged-state') || '{}');
}

function getFromStorage(keyPath: string[]): Record<string, any> {
  const fullState = getEntireStateFromStorage();
  return path(keyPath, fullState) || {};
}

function setIntoStorage(keyPath: string[], data: any): void {
  const updatedState = assocPath(keyPath, data, getEntireStateFromStorage());
  localStorage.setItem('triaged-state', JSON.stringify(updatedState));
}

export function deleteFromStorage(keyPath: string[]): void {
  const updatedState = dissocPath(keyPath, getEntireStateFromStorage());
  localStorage.setItem('triaged-state', JSON.stringify(updatedState));
}

// if fieldsToState is defined, then apply those property-level transformations to data. Otherwise, leave it unchanged.
// (fieldsToState should be a hashmap that maps properties to their transformation functions)
function transformData(fieldsToState?: FieldsToState) {
  return (data: Record<string, any>) => {
    if (fieldsToState && Object.keys(fieldsToState).length > 0) {
      return Object.keys(data).reduce((acc, key) => {
        return {
          ...acc,
          [key]: fieldsToState[key] ? fieldsToState[key](data[key]) : data[key],
        };
      }, {});
    }

    return data;
  };
}

// handle the calling of the provider dispatch function (and callback, if it was provided)
// note that the provider's reducer should implement a "restore-from-local-storage" action
function updateState(
  keyPath: string[],
  dispatch: (action: any) => void,
  callback?: (data: any) => void
) {
  return (data: Record<string, any>) => {
    if (Object.keys(data).length > 0) {
      dispatch({
        type: 'restore-from-local-storage',
        payload: { data, keyPath },
      });
      if (callback) {
        callback(data);
      }
      return true;
    }
    return false;
  };
}

// load state data from localStorage
function loadState({ keyPath, dispatch, fieldsToState, callback }: LoadParams) {
  return compose(
    updateState(keyPath, dispatch, callback), // finally, call the dispatch function with the (potentially transformed) data
    transformData(fieldsToState), // then optionally transform data according to fieldsToState
    getFromStorage // first get data from local storage
  )(keyPath);
}

// hook used by context providers to restore state from localStorage
// the `onEmpty` function (alternatively, `callback`) can be used if no data was loaded
// => may need to call some initialization side-effect
export function useLocalStorageState(
  params: LoadParams
): {
  saveState: (state: Record<string, any> | null) => void;
} {
  // is there a cleaner way to do this?
  // ensure that loadState is called only once; can't rely on JS "equality" checks with deps
  useEffect(() => {
    const loadedFromLocalStorage = loadState(params);
    if (!loadedFromLocalStorage && params.onEmpty) {
      params.onEmpty();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    // saveState can be used to update localStorage when state has changed (e.g., in a useEffect callback)
    saveState: (state: Record<string, any> | null) => {
      setIntoStorage(params.keyPath, state || {});
    },
  };
}
