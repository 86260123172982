import { get, getFile } from '../utils/httpUtils';

const endpoint = '/v1/frontend/doc/';

export type DocumentData = {
  id: number;
  name: string;
  live: boolean;
  lastEditUser: string;
  lastEditDate: Date;
  version: number;
  title: string;
  filename: string;
  previewAvailable: boolean;
  keywords: string[];
} | null;

export function getDocumentFile(id: string): Promise<File> {
  return getFile(endpoint + id + '/download', undefined);
}

export function getDocumentPreview(id: string): Promise<File> {
  return getFile(endpoint + id + '/download?preview=true', undefined);
}

export function getDocument(id: string): Promise<DocumentData> {
  return get<any>(endpoint + id, undefined);
}
