import Immutable from 'immutable';

export const endpoint = '/api/v1/frontend/getQuestion';

export interface Question {
  surveyId: number;
  id: number;
  text: string;
}

export interface AnswerOption {
  id: number;
  text: string;
  target: number;
  props: Immutable.Map<string, any>;
  ansValue?: string; // text inputs
  type?: string; // not in v1?
  placeholder?: string; // not in v1?
}

export type QuestionData = {
  answerId: number;
  questions: Immutable.List<Immutable.Record<Question>>;
  answerOptions: Immutable.List<Immutable.Record<AnswerOption>>;
  completedSurveyId?: number;
  createPatient?: boolean;
  surveyFinished?: 'finished' | 'reset' | 'createPatient' | 'url';
  url?: string;
};

type NextQuestionPayload = {
  previousAnswerId: number;
  thisAnswer: {
    surveyId: number;
    edgeNumber: number;
    targetNode: number;
    nodeNumber: number;
    ansValue?: string | undefined;
  };
};

export function searchToAnswerOption(
  searchTerm: string
): {
  id: number;
  text: string;
  target: number;
  props: {
    directsearch: boolean;
  };
} {
  return {
    id: 0,
    text: searchTerm,
    target: 0,
    props: {
      directsearch: true,
    },
  };
}

// Generate request body for remote consultation options
export function getConsultationOptionsPayload(): {
  previousAnswerId: number;
} {
  return {
    previousAnswerId: 0,
  };
}

export function getQueryPayload(query: {
  q: string | null;
  v: string | null;
}): {
  previousAnswerId: number;
  startSurvey: string;
} {
  const { q, v } = query;
  return {
    previousAnswerId: 0,
    startSurvey: v ? `${q}|${v}` : `${q}`,
  };
}

// Generate request body for direct search case (allKeywords -> search field input)
export function getDirectSearchPayload(
  searchTerm: string
): {
  previousAnswerId: number;
  startSurvey: string;
  variables: {
    0: {
      directsearch: string;
    };
  };
} {
  return {
    previousAnswerId: 0,
    startSurvey: '',
    variables: { 0: { directsearch: searchTerm } },
  };
}

// Generate request body for next API call by looking at previous response data and answer option
export function getNextQuestionPayload(
  question: Immutable.Record<QuestionData>,
  answer: Immutable.Record<AnswerOption>
): NextQuestionPayload {
  // For text inputs, we need to store the <input> value
  const textInput =
    (answer.get('ansValue') && { ansValue: answer.get('ansValue') }) || {};

  return {
    previousAnswerId: question.get('answerId'),
    thisAnswer: {
      ...textInput,
      surveyId: question
        .get('questions')
        .last<Immutable.Record<Question>>()
        .get('surveyId'), //.slice(-1)[0].surveyId,
      edgeNumber: answer.get('id'),
      targetNode: answer.get('target'),
      nodeNumber: question
        .get('questions')
        .last<Immutable.Record<Question>>()
        .get('id'), //.slice(-1)[0].id,
    },
  };
}

// function postGetQuestion(data: any): Promise<QuestionData> {
//   return post<any>(endpoint, data, undefined);
// }

// export function useGetQuestion() {
//   return makeRequestHook<any, Record<string, any>>(postGetQuestion)(undefined);
// }
